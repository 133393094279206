import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTable from "components/ReactTable/ReactTable";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { styled } from "@material-ui/core";
import TextField2 from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DateRange } from "react-date-range";
import Close from "@material-ui/icons/Close";
import jwt_decode from "jwt-decode";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
const useStyles = makeStyles(styles);

const CssTextField = styled(TextField2)({
  "& label.Mui-focused": {
    color: "#4caf50",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#4caf50",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "current",
    },
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    "&.Mui-focused fieldset": {
      borderColor: "#4caf50",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Planilla() {
  const classes = useStyles();
  const [data, setData] = useState();
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();
  const [rangeModal, setRangeModal] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const getData = async () => {
    let decoded = jwt_decode(localStorage.jwtToken);
    if (decoded.app[0].codigoPrivilegio === 2) {
      await axios
        // .get(`http://localhost:5005/api/rdtt/user-planilla/${decoded.id}`)
        .get(`https://epstool.com/api/rdtt/user-planilla/${decoded.id}`)
        .then((res) => setData(res.data));
    } else if (decoded.app[0].codigoPrivilegio === 3) {
      await axios
        .get(`https://epstool.com/api/rdtt/user-planilla`)
        // .get(`http://localhost:5005/api/rdtt/user-planilla`)
        .then((res) => setData(res.data));
    }
  };
  // console.log(localStorage.userConfig, "userConfig");
  console.log(data, "data");
  console.log(desde, hasta);

  useEffect(() => {
    getData();
  }, []);

  const handleDate = (e) => {
    e[0] !== null ? setDesde(e[0].getTime() / 1000) : setDesde("");

    e[1] !== null ? setHasta(e[1].getTime() / 1000) : setHasta("");
  };

  let decoded = jwt_decode(localStorage.jwtToken);
  const searchByDates = async () => {
    if (decoded.app[0].codigoPrivilegio === 2) {
      await axios
        .get(
          `https://epstool.com/api/rdtt/user-planilla/${desde}/${hasta}/${decoded.id}`
          // `http://localhost:5005/api/rdtt/user-planilla/${desde}/${hasta}/${decoded.id}`
        )
        .then((res) => setData(res.data));
    } else if (decoded.app[0].codigoPrivilegio === 3) {
      await axios
        .get(`https://epstool.com/api/rdtt/user-planilla/${desde}/${hasta}`)
        // .get(`http://localhost:5005/api/rdtt/user-planilla/${desde}/${hasta}`)
        .then((res) => setData(res.data));
    }
  };
  console.log(data);

  const refreshSearchDates = () => {
    setDesde();
    setHasta();
    window.location.reload();
  };

  let reportes;
  data
    ? (reportes = data.map((r) => {
        return {
          usuario: r.user,
          asignados: r.asignados,
          nuevoAsignados: r.nuevoAsignados,
          enProceso: r.proceso,
          terminados: r.terminados,
          reasignados: r.reasignados,
          meAsignaron: r.meAsignaron,
          auxiliares: r.auxiliares,
        };
      }))
    : (reportes = []);

  return (
    <GridContainer>
      <Card>
        <CardBody>
          <InputLabel className={classes.label}>Búsqueda por fecha</InputLabel>
          <GridContainer justify="center">
            <GridItem xs={12} md={4}>
              <CssTextField
                fullWidth
                label="Desde"
                id="custom-css-outlined-input"
                value={desde ? new Date(desde * 1000).toLocaleDateString() : ""}
                onClick={() => setRangeModal(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateRangeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>

            <GridItem xs={12} md={4}>
              <CssTextField
                fullWidth
                label="Hasta"
                id="custom-css-outlined-input"
                value={hasta ? new Date(hasta * 1000).toLocaleDateString() : ""}
                onClick={() => setRangeModal(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateRangeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} md={2}>
              {!desde || !hasta ? (
                <Button
                  disabled
                  fullWidth
                  color="success"
                  onClick={searchByDates}
                  className={classes.marginRight}
                >
                  Calcular
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="success"
                  onClick={searchByDates}
                  className={classes.marginRight}
                >
                  Calcular
                </Button>
              )}
            </GridItem>
            <GridItem xs={12} md={2}>
              {!desde || !hasta ? (
                <Button
                  disabled
                  fullWidth
                  color="success"
                  onClick={refreshSearchDates}
                  className={classes.marginRight}
                >
                  Refrescar
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="success"
                  onClick={refreshSearchDates}
                  className={classes.marginRight}
                >
                  Refrescar
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>Planilla</Typography>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              {decoded.app[0].codigoPrivilegio === 3 ? (
                <ReactTable
                  xs={12}
                  columns={[
                    {
                      Header: "Usuarios",
                      accessor: "usuario",
                    },
                    {
                      Header: "Total",
                      accessor: "asignados",
                    },
                    {
                      Header: "EnProceso",
                      accessor: "enProceso",
                    },
                    {
                      Header: "Nuevo",
                      accessor: "nuevoAsignados",
                    },
                    {
                      Header: "Terminados",
                      accessor: "terminados",
                    },
                    {
                      Header: "Asignados",
                      accessor: "meAsignaron",
                    },
                    {
                      Header: "Reasignados",
                      accessor: "reasignados",
                    },
                    {
                      Header: "Auxiliares",
                      accessor: "auxiliares",
                    },
                    {
                      Header: "",
                      accessor: "-",
                    },
                  ]}
                  data={reportes}
                />
              ) : (
                <ReactTable
                  xs={12}
                  columns={[
                    {
                      Header: "Usuarios",
                      accessor: "usuario",
                    },
                    {
                      Header: "Total",
                      accessor: "asignados",
                    },
                    {
                      Header: "EnProceso",
                      accessor: "enProceso",
                    },
                    {
                      Header: "Nuevo",
                      accessor: "nuevoAsignados",
                    },
                    {
                      Header: "Terminados",
                      accessor: "terminados",
                    },
                    {
                      Header: "",
                      accessor: "-",
                    },
                  ]}
                  data={reportes}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={rangeModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setRangeModal(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setRangeModal(false);
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setState([item.selection]);
              handleDate([item.selection.startDate, item.selection.endDate]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </DialogContent>
        <DialogActions
          className={classes.modalFooter}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            onClick={() => {
              setRangeModal(false);
            }}
            color=""
            style={{ backgroundColor: "rgb(61, 145, 255)" }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
