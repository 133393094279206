import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

//MUI
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

//Components MUI internos
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";

// @material-ui/core components styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

//React DateTime
import Datetime from "react-datetime";
//MOCK
import { categorias } from "mock/_categorias";
//Api call
import axios from "axios";
import { estados } from "mock/_categorias";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Estadisticas() {
  const classes = useStyles();
  const [data, setData] = useState();
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();
  const [estado, setEstado] = useState("");
  const [users, setUsers] = useState();
  const [tecnico, setTecnico] = useState("");
  const [tipoGrafico, setTipoGrafico] = useState("bars");

  const getData = async () => {
    const resultUsers = await axios.get(
      `https://epstool.com/api/users/userstecnicos`
      // `http://localhost:5005/api/users/userstecnicos`
      // `http://localhost:5005/api/userRdtt/users`
    );
    // console.log(resultUsers.data)
    setUsers(resultUsers.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleFrom = (e) => {
    setDesde(e.unix());
  };
  const handleTo = (e) => {
    setHasta(e.unix());
  };

  // console.log("desde", desde);
  // console.log("hasta", hasta);
  // console.log("categoria", categoria);
  // console.log("users", users);
  // console.log("tecnico", tecnico);

  const graficSubmit = async () => {
    let data = {
      desde,
      hasta,
      estado,
      tecnico,
    };
    await axios
      .post(`http://localhost:5005/api/rdtt/estadisticas`, data)
      .then((res) => {
        setData(res.data.data);
      });
  };

  const dataGrafic = {
    series: [
      {
        name: "total",
        data: data ? data : [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -30,
        },
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Electrico",
          "Ehs",
          "Area mecanico",
          "Producción",
          "Emergencia",
          "Cortinas",
          "Otro",
          "Proyectos",
          "Mantenimiento",
        ],
      },
      yaxis: {
        title: {
          text: "Cantidad por categoria",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  const donutsGrafic = {
    series: data ? data : [0, 0, 0, 0, 0, 0, 0, 0, 0],
    options: {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -30,
        },
        width: 380,
        type: "donut",
        dropShadow: {
          enabled: true,
          color: "#111",
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2,
        },
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
              },
            },
          },
        },
      },
      labels: [
        "Electrico",
        "Ehs",
        "Area mecanico",
        "Producción",
        "Emergencia",
        "Cortinas",
        "Otro",
        "Proyectos",
        "Mantenimiento",
      ],
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      title: {
        text: "",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const handleReset = () => {
    setDesde();
    setHasta();
    setData("");
    setTecnico("");
    setEstado("");
  };

  console.log("data grfica", data);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success" text>
            <CardText color="success">
              <h4 style={{ color: "#fff" }} className={classes.cardTitle}>
                Configuración Gráficos
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridItem xs={12}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Seleccione rango de fecha
              </FormLabel>
              <GridContainer
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <GridItem xs={12} sm={5}>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      onChange={handleFrom}
                      timeFormat={false}
                      inputProps={{ placeholder: "Desde" }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={5}>
                  <br />

                  <FormControl fullWidth>
                    <Datetime
                      onChange={handleTo}
                      timeFormat={false}
                      inputProps={{ placeholder: "Hasta" }}
                    />
                  </FormControl>
                </GridItem>

                <br />
              </GridContainer>
            </GridItem>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: 4,
              }}
            >
              <GridItem xs={12} sm={5}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Selecione Estado
                  </InputLabel>
                  <Select
                    value={estado}
                    // onChange={(e) => handleMaquinas(e.target.value)}
                    onChange={(e) => setEstado(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    {estados &&
                      estados.map((el, i) => (
                        <MenuItem
                          key={i}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={el.value}
                        >
                          {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Selecione Tecnico
                  </InputLabel>
                  <Select
                    value={tecnico}
                    // onChange={(e) => handleMaquinas(e.target.value)}
                    onChange={(e) => setTecnico(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    {users &&
                      users.map((el, i) => (
                        <MenuItem
                          key={el._id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={el._id}
                        >
                          {i + 1} - {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginLeft: 30 }}>
              <GridItem xs={12} sm={5}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <FormLabel className={classes.labelCustomHorizontal}>
                    Seleccione tipo de gráfico
                  </FormLabel>
                  <Select
                    value={tipoGrafico}
                    // onChange={(e) => handleMaquinas(e.target.value)}
                    onChange={(e) => setTipoGrafico(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    <MenuItem
                      value="bars"
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Bars
                    </MenuItem>
                    <MenuItem
                      value="donuts"
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Donuts
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: 10,
              }}
            >
              <GridItem xs={12} sm={5} md={5}>
                <Button fullWidth color="success" onClick={graficSubmit}>
                  Generar Gráfico
                </Button>
              </GridItem>
              <GridItem xs={12} sm={5} md={5}>
                {desde && (
                  <Button onClick={handleReset} fullWidth color="success">
                    Refrescar
                  </Button>
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      {data ? (
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card style={{ padding: 10, width: "80%" }}>
            <GridContainer style={{ padding: 10 }}>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="h6">
                  Total de Tiquetes por Categoria
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Chart
                  options={
                    tipoGrafico === "bars"
                      ? dataGrafic.options
                      : donutsGrafic.options
                  }
                  series={
                    tipoGrafico === "bars"
                      ? dataGrafic.series
                      : donutsGrafic.series
                  }
                  type={tipoGrafico === "bars" ? "bar" : "donut"}
                  height={tipoGrafico === "bars" ? 500 : 400}
                />
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      ) : (
        ""
      )}
    </GridContainer>
  );
}
