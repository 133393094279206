import React, { useState, useEffect } from "react";
//Api call
import axios from "axios";
//MUI
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Chart from "react-apexcharts";

// @material-ui/core components styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function TotalDonutPrioridad() {
  const classes = useStyles();
  const [data, setData] = useState();
  const [users, setUsers] = useState();
  const [tecnico, setTecnico] = useState("0");
  const getData = async () => {
    const resultUsers = await axios.get(
      `https://epstool.com/api/users/userstecnicos`
      // `http://localhost:5005/api/users/userstecnicos`
      // `http://localhost:5005/api/userRdtt/users`
    );
    // console.log(resultUsers.data)
    setUsers(resultUsers.data);

    const data = {
      tecnico,
    };
    await axios
      .post(`http://localhost:5005/api/rdtt/estadisticas-prioridad`, data)
      .then((res) => {
        setData(res.data.data);
      });
  };

  useEffect(() => {
    getData();
  }, [tecnico]);

  const dataGrafic = {
    series: data ? data : [0, 0],
    options: {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -100,
        },
        width: 380,
        type: "donut",
        dropShadow: {
          enabled: true,
          color: "#111",
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2,
        },
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
              },
            },
          },
        },
      },
      labels: ["Normal", "Emergencia"],
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      title: {
        text: "",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <Card style={{ padding: 5, height: "100%" }}>
      <GridContainer style={{ padding: 4 }}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="h6">Total de Tiquetes por prioridad</Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="multiple-select"
              className={classes.selectLabel}
            >
              Selecione Tecnico
            </InputLabel>
            <Select
              value={tecnico}
              // onChange={(e) => handleMaquinas(e.target.value)}
              onChange={(e) => setTecnico(e.target.value)}
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Selecione
              </MenuItem>
              <MenuItem
                value={"0"}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Todos
              </MenuItem>
              {users &&
                users.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={el._id}
                  >
                    {i + 1} - {el.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Chart
            options={dataGrafic.options}
            series={dataGrafic.series}
            type="donut"
            height={800}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}
