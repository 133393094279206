import React, { useState, useEffect } from "react";
import axios from "axios";
import Forward from "@material-ui/icons/Forward";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTable from "components/ReactTable/ReactTable";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import jwt_decode from "jwt-decode";
import DiscFullIcon from "@material-ui/icons/DiscFull";

const useStyles = makeStyles(styles);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
};

export default function NewExternalData() {
  const classes = useStyles();
  const history = useHistory();
  const [newData, setNewData] = useState();
  const [dataDetails, setDataDetails] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [jwtCode, setJwtCode] = useState();

  const getData = async () => {
    let decoded = jwt_decode(localStorage.jwtToken);
    setJwtCode(decoded);
    if (decoded.app[0].codigoPrivilegio === 3) {
      const info = await axios.get(
        // `http://localhost:5005/api/rdtt/trabajosTecnicos`
        `https://epstool.com/api/rdtt/trabajosTecnicos`
      );
      setNewData(info.data.filter((el) => el.estado === 0));
    }
  };

  // CARGA INICIAL
  useEffect(() => {
    getData();
  }, []);

  const splitAndGetSpecificData = async (id) => {
    handleOpen();
    const especificData = await axios.get(
      // `http://localhost:5005/api/rdtt/detalle/${id}`
      `https://epstool.com/api/rdtt/detalle/${id}`
    );
    setDataDetails(especificData.data);
  };

  let reportes;
  newData
    ? (reportes = newData.map((r) => {
        return {
          codigo: r.codigo,
          estado:
            r.estado === 0 && r.asignar.length === 0
              ? "Nuevo"
              : "" || (r.asignar.length > 0 && r.estado === 0)
              ? "Escalado"
              : "" || r.estado === 1
              ? "Nuevo asignado"
              : "" || r.estado === 2
              ? "En proceso"
              : "" || r.estado === 3
              ? "Terminado"
              : "" || r.estado === 4
              ? "pausado"
              : "",
          detalle: r.reporte,
          tecnico: "No asignado",
          solicitante: r.solicitante,
          categoria: r.categoria,

          actions: (
            <div className="actions-right">
              {r.prioridad === "emergencia" ? (
                <Tooltip title="Prioridad: EMERGENCIA">
                  <IconButton style={{ color: "red" }}>
                    <DiscFullIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
              <Tooltip title="Detalle">
                <IconButton
                  onClick={() => splitAndGetSpecificData(r.codigo)}
                  style={{ color: "#4caf50" }}
                >
                  <Forward />
                </IconButton>
              </Tooltip>
            </div>
          ),
        };
      }))
    : (reportes = []);

  // console.log(
  //   dataDetails && dataDetails.codigo ? dataDetails.codigo : "",
  //   "cod"
  // );

  return (
    <>
      {newData && newData.length > 0 ? (
        <Card>
          <CardBody>
            <GridContainer style={{ marginBottom: 30 }}>
              <GridItem xs={6} sm={9}>
                <Typography>Reportes nuevos sin asignar y escalados</Typography>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                {jwtCode && jwtCode.app[0].codigoPrivilegio === 3 && (
                  <ReactTable
                    columns={[
                      {
                        Header: "Codigo",
                        accessor: "codigo",
                      },
                      {
                        Header: "Estado",
                        accessor: "estado",
                      },
                      {
                        Header: "Detalle",
                        accessor: "detalle",
                      },
                      {
                        Header: "Tecnico",
                        accessor: "tecnico",
                      },

                      {
                        Header: "Categoria",
                        accessor: "categoria",
                      },
                      {
                        Header: "Solicitante",
                        accessor: "solicitante",
                      },

                      {
                        Header: "Accion",
                        accessor: "actions",
                      },
                    ]}
                    data={reportes}
                    sortDefault="codigo"
                  />
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      ) : (
        ""
      )}
      <GridItem>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="success"
            >
              <GridItem xs={12} md={12} lg={12}>
                <Typography variant="h4" align="center">
                  Detalle de reporte
                </Typography>
              </GridItem>
            </CardHeader>
            <GridContainer style={{ marginTop: 10, paddingLeft: 15 }}>
              <GridItem xs={12} md={12} style={{ marginTop: 12 }}>
                <Typography>
                  Fecha de creacion:{" "}
                  {dataDetails && dataDetails.fechaCreacion
                    ? new Date(
                        dataDetails.fechaCreacion * 1000
                      ).toLocaleString()
                    : ""}
                </Typography>
              </GridItem>
              <GridItem xs={12} md={12} style={{ marginTop: 25 }}>
                <Typography>
                  Categoria: {dataDetails ? dataDetails.categoria : ""}
                </Typography>
              </GridItem>
              <GridItem xs={12} md={12} style={{ marginTop: 25 }}>
                <Typography>
                  Reporte: {dataDetails ? dataDetails.reporte : ""}
                </Typography>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                align="center"
                xs={12}
                md={12}
                style={{ marginTop: 12 }}
              >
                <Button
                  style={{ width: "90%" }}
                  onClick={() =>
                    history.push(
                      `/admin/detalle/${
                        dataDetails && dataDetails.codigo
                          ? dataDetails.codigo
                          : ""
                      }`
                    )
                  }
                  fullWidth
                  color="success"
                >
                  Ver mas...
                </Button>
              </GridItem>
            </GridContainer>
          </Box>
        </Modal>
      </GridItem>
    </>
  );
}
