import React, { useEffect, useState } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Forward from "@material-ui/icons/Forward";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PauseIcon from "@material-ui/icons/Pause";
import DiscFullIcon from "@material-ui/icons/DiscFull";
import Pausa from "views/Forms/Pausa";
import ReporteFinalizado from "views/Forms/ReporteFinalizado";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import setAuthToken from "../../utils/setAuthToken";

// core components
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader.js";
import jwt_decode from "jwt-decode";
import NewExternalData from "./components/NewExternalData";
import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import TextField2 from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DateRange } from "react-date-range";
import Close from "@material-ui/icons/Close";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import FinishData from "./components/FinishData";

const useStyles = makeStyles(styles);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
};

const CssTextField = styled(TextField2)({
  "& label.Mui-focused": {
    color: "#4caf50",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#4caf50",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "current",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4caf50",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Dashboard(props) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [filterData, setFilterData] = useState();
  const [changes, setChanges] = useState(0);

  const [dataDetails, setDataDetails] = useState();
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();
  const [estado, setEstado] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPausa, setOpenPausa] = useState(false);
  const handleOpenPausa = () => setOpenPausa(true);
  const handleClosePausa = () => setOpenPausa(false);

  const [openFinish, setOpenFinish] = useState(false);
  const handleOpenFinish = () => setOpenFinish(true);
  const handleCloseFinish = () => setOpenFinish(false);

  const [cod, setCod] = useState();
  const [rangeModal, setRangeModal] = useState(false);
  const [alertStart, setAlertStart] = useState(false);

  const [jwtCode, setJwtCode] = useState();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const getData = async () => {
    let decoded;
    try {
      decoded = jwt_decode(localStorage ? localStorage.jwtToken : "");
    } catch (error) {
      setChanges(changes + 1);
    }
    setJwtCode(decoded);

    if (decoded.app[0].codigoPrivilegio === 2) {
      console.log(localStorage.userConfig);
      const info = await axios.get(
        // `http://localhost:5005/api/rdtt/trabajosTecnicos/${decoded.id}`
        `https://epstool.com/api/rdtt/trabajosTecnicos/${decoded.id}`
      );
      // setData(info.data);
      setData(info.data.filter((el) => el.estado !== 3));
    } else if (decoded.app[0].codigoPrivilegio === 3) {
      const info = await axios.get(
        // `http://localhost:5005/api/rdtt/trabajosTecnicos`
        `https://epstool.com/api/rdtt/trabajosTecnicos`
      );
      // setData(info.data);
      setData(info.data.filter((el) => el.estado !== 3));
    }
    setDesde();
    setHasta();
  };

  // CARGA INICIAL
  useEffect(() => {
    // setAuthToken(localStorage.jwtToken);

    getData();
  }, [changes]);

  const handleDate = (e) => {
    e[0] !== null ? setDesde(e[0].getTime() / 1000) : setDesde("");

    e[1] !== null ? setHasta(e[1].getTime() / 1000) : setHasta("");
  };

  const handleStart = async (e, cod) => {
    e.preventDefault();
    let info = data.filter((el) => el.estado === 2);
    for (let i = 0; i < info.length; i++) {
      // axios.put(`http://localhost:5005/api/rdtt/pausado/${info[i].codigo}`);
      await axios.put(`https://epstool.com/api/rdtt/pausado/${info[i].codigo}`);
    }

    // await axios.post(`http://localhost:5005/api/rdtt/iniciar/${cod}`);
    await axios.post(`https://epstool.com/api/rdtt/iniciar/${cod}`);
    setChanges(changes + 1);
    setAlertStart(false);

    // console.log("cod", cod);
  };

  const startModalConfirm = (cod) => {
    setEstado(cod);
    setAlertStart(true);
  };

  const splitAndGetSpecificData = async (id) => {
    handleOpen();
    const especificData = await axios.get(
      // `http://localhost:5005/api/rdtt/detalle/${id}`
      `https://epstool.com/api/rdtt/detalle/${id}`
    );
    setDataDetails(especificData.data);
  };

  const execution = (cod) => {
    handleOpenFinish();
    setCod(cod);
  };

  const executionPausa = (cod) => {
    handleOpenPausa();
    setCod(cod);
  };

  const handleReanudar = async (e, cod) => {
    e.preventDefault();
    let info = data.filter((el) => el.estado === 2);
    console.log("infoPausa", info);
    for (let i = 0; i < info.length; i++) {
      // axios.put(`http://localhost:5005/api/rdtt/pausado/${info[i].codigo}`);
      await axios.put(`https://epstool.com/api/rdtt/pausado/${info[i].codigo}`);
      console.log("states", info[i].estado);
    }
    // axios.put(`http://localhost:5005/api/rdtt/reanudar-pausa/${cod}`);
    await axios.put(`https://epstool.com/api/rdtt/reanudar-pausa/${cod}`);
    setChanges(changes + 1);
  };

  const searchByDates = async () => {
    await axios
      // .get(`http://localhost:5005/api/rdtt//date/${desde}/${hasta}`)
      .get(`https://epstool.com/api/rdtt/date/${desde}/${hasta}`)
      .then((res) => setData(res.data));
    // setData(result)
  };
  console.log("data", data);

  let reportes;
  data
    ? (reportes = data.map((r) => {
        return {
          codigo: r.codigo,
          estado:
            r.estado === 0
              ? "Nuevo"
              : "" || r.estado === 1
              ? "Nuevo asignado"
              : "" || r.estado === 2
              ? "En proceso"
              : "" || r.estado === 3
              ? "Terminado"
              : "" || r.estado === 4
              ? "pausado"
              : "",
          detalle: r.reporte,
          solicitante: r.solicitante,
          tecnico:
            r.asignar.length >= 1 && r.asignar[0].user !== null
              ? r.asignar[0].user.name
              : "No asignado",
          categoria: r.categoria,
          actions: (
            <div>
              {r.estado === 0 && (
                <div className="actions-right">
                  {r.prioridad === "emergencia" ? (
                    <Tooltip title="Prioridad: EMERGENCIA">
                      <IconButton style={{ color: "red" }}>
                        <DiscFullIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip title="Detalle">
                    <IconButton
                      onClick={() => splitAndGetSpecificData(r.codigo)}
                      style={{ color: "#4caf50" }}
                    >
                      <Forward />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {r.estado === 1 && (
                <div className="actions-right">
                  {r.prioridad === "emergencia" ? (
                    <Tooltip title="Prioridad: EMERGENCIA">
                      <IconButton style={{ color: "red" }}>
                        <DiscFullIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip title="Iniciar">
                    <IconButton
                      onClick={() => startModalConfirm(r.codigo)}
                      style={{ color: "#4caf50" }}
                    >
                      <PlayCircleFilledWhiteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Detalle">
                    <IconButton
                      onClick={() => splitAndGetSpecificData(r.codigo)}
                      style={{ color: "#4caf50" }}
                    >
                      <Forward />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {r.estado === 3 && (
                <div className="actions-right">
                  {r.prioridad === "emergencia" ? (
                    <Tooltip title="Prioridad: EMERGENCIA">
                      <IconButton style={{ color: "red" }}>
                        <DiscFullIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip title="Reporte Finalizado">
                    <IconButton style={{ color: "#bdbdbd" }}>
                      <PlayCircleFilledWhiteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Detalle">
                    <IconButton
                      onClick={() => splitAndGetSpecificData(r.codigo)}
                      style={{ color: "#4caf50" }}
                    >
                      <Forward />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {r.estado === 2 && (
                <div className="actions-right">
                  {r.prioridad === "emergencia" ? (
                    <Tooltip title="Prioridad: EMERGENCIA">
                      <IconButton style={{ color: "red" }}>
                        <DiscFullIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip title="Finalizar">
                    <IconButton
                      onClick={() => {
                        execution(r.codigo);
                      }}
                      style={{ color: "#03a9f4" }}
                    >
                      <AssignmentTurnedInIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Pausa">
                    <IconButton
                      onClick={() => {
                        executionPausa(r.codigo);
                      }}
                    >
                      <PauseIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Detalle">
                    <IconButton
                      onClick={() => splitAndGetSpecificData(r.codigo)}
                      style={{ color: "#4caf50" }}
                    >
                      <Forward />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {r.estado === 4 && (
                <div className="actions-right">
                  {r.prioridad === "emergencia" ? (
                    <Tooltip title="Prioridad: EMERGENCIA">
                      <IconButton style={{ color: "red" }}>
                        <DiscFullIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip title="Reanudar">
                    <IconButton
                      onClick={(e) => {
                        handleReanudar(e, r.codigo);
                      }}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Detalle">
                    <IconButton
                      onClick={() => splitAndGetSpecificData(r.codigo)}
                      style={{ color: "#4caf50" }}
                    >
                      <Forward />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          ),
        };
      }))
    : (reportes = []);

  return (
    <GridContainer>
      <Card>
        <CardBody>
          <InputLabel className={classes.label}>Búsqueda por fecha</InputLabel>
          <GridContainer justify="center">
            <GridItem xs={12} md={4}>
              <CssTextField
                fullWidth
                label="Desde"
                id="custom-css-outlined-input"
                value={desde ? new Date(desde * 1000).toLocaleDateString() : ""}
                onClick={() => setRangeModal(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateRangeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>

            <GridItem xs={12} md={4}>
              <CssTextField
                fullWidth
                label="Hasta"
                id="custom-css-outlined-input"
                value={hasta ? new Date(hasta * 1000).toLocaleDateString() : ""}
                onClick={() => setRangeModal(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateRangeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} md={2}>
              {!desde || !hasta ? (
                <Button
                  disabled
                  fullWidth
                  color="success"
                  onClick={searchByDates}
                  className={classes.marginRight}
                >
                  Calcular
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="success"
                  onClick={searchByDates}
                  className={classes.marginRight}
                >
                  Calcular
                </Button>
              )}
            </GridItem>
            <GridItem xs={12} md={2}>
              {!desde || !hasta ? (
                <Button
                  disabled
                  fullWidth
                  color="success"
                  onClick={getData}
                  className={classes.marginRight}
                >
                  Refrescar
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="success"
                  onClick={getData}
                  className={classes.marginRight}
                >
                  Refrescar
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <NewExternalData />
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={9} md={10}>
              <Typography>
                {desde && hasta
                  ? `Reportes desde ${new Date(
                      desde * 1000
                    ).toLocaleDateString()} hasta ${new Date(
                      hasta * 1000
                    ).toLocaleDateString()}`
                  : "Reportes de los ultimos 7 dias"}
              </Typography>
            </GridItem>
            <GridItem xs={6} sm={3} md={2}>
              <Button
                onClick={() => props.history.push("/admin/crearReporte")}
                color="success"
              >
                Nuevo reporte
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              {jwtCode && jwtCode.app[0].codigoPrivilegio === 3 ? (
                <ReactTable
                  columns={[
                    {
                      Header: "Codigo",
                      accessor: "codigo",
                    },
                    {
                      Header: "Estado",
                      accessor: "estado",
                    },
                    {
                      Header: "Detalle",
                      accessor: "detalle",
                    },
                    {
                      Header: "Tecnico",
                      accessor: "tecnico",
                    },
                    {
                      Header: "Solicitante",
                      accessor: "solicitante",
                    },
                    {
                      Header: "Categoria",
                      accessor: "categoria",
                    },
                    {
                      Header: "Accion",
                      accessor: "actions",
                    },
                  ]}
                  data={reportes}
                />
              ) : (
                <ReactTable
                  columns={[
                    {
                      Header: "Codigo",
                      accessor: "codigo",
                    },
                    {
                      Header: "Estado",
                      accessor: "estado",
                    },
                    {
                      Header: "Detalle",
                      accessor: "detalle",
                    },
                    {
                      Header: "Solicitante",
                      accessor: "solicitante",
                    },
                    {
                      Header: "Categoria",
                      accessor: "categoria",
                    },
                    {
                      Header: "Accion",
                      accessor: "actions",
                    },
                  ]}
                  data={reportes}
                  sortDefault="codigo"
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <FinishData />
      <GridContainer>
        <GridItem>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.center}`}
                color="success"
              >
                <GridItem xs={12} md={12} lg={12}>
                  <Typography variant="h4" align="center">
                    Detalle de reporte
                  </Typography>
                </GridItem>
              </CardHeader>
              <GridContainer style={{ marginTop: 10, paddingLeft: 15 }}>
                <GridItem xs={12} md={12} style={{ marginTop: 25 }}>
                  <Typography>
                    Fecha de creacion:{" "}
                    {dataDetails && dataDetails.fechaCreacion
                      ? new Date(
                          dataDetails.fechaCreacion * 1000
                        ).toLocaleString()
                      : ""}
                  </Typography>
                </GridItem>
                <GridItem xs={12} md={12} style={{ marginTop: 25 }}>
                  <Typography>
                    Categoria: {dataDetails ? dataDetails.categoria : ""}
                  </Typography>
                </GridItem>
                <GridItem xs={12} md={12} style={{ marginTop: 25 }}>
                  <Typography>
                    Reporte: {dataDetails ? dataDetails.reporte : ""}
                  </Typography>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  align="center"
                  xs={12}
                  md={12}
                  style={{ marginTop: 12 }}
                >
                  <Button
                    style={{ width: "90%" }}
                    onClick={() =>
                      props.history.push(
                        `/admin/detalle/${
                          dataDetails && dataDetails.codigo
                            ? dataDetails.codigo
                            : ""
                        }`
                      )
                    }
                    color="success"
                  >
                    Ver mas...
                  </Button>
                </GridItem>
              </GridContainer>
            </Box>
          </Modal>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Pausa
          handleClosePausa={handleClosePausa}
          cod={cod}
          openPausa={openPausa}
        />
      </GridContainer>
      <GridContainer>
        <ReporteFinalizado
          cod={cod}
          handleCloseFinish={handleCloseFinish}
          openFinish={openFinish}
        />
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={rangeModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setRangeModal(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setRangeModal(false);
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setState([item.selection]);
              handleDate([item.selection.startDate, item.selection.endDate]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </DialogContent>
        <DialogActions
          className={classes.modalFooter}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            onClick={() => {
              setRangeModal(false);
            }}
            color=""
            style={{ backgroundColor: "rgb(61, 145, 255)" }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={alertStart}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setAlertStart(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{ backgroundColor: "#F9F5EB" }}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setAlertStart(false);
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ backgroundColor: "#F9F5EB" }}
        >
          <GridItem
            style={{
              width: "350px",
              height: "150px",
            }}
          >
            <Typography variant="h5">
              ¿Estas seguro de querer iniciar este trabajo?
            </Typography>
            <br />
            <Typography variant="inherit">
              - Esta accion no se puede deshacer, si inicas el trabajo no habra
              manera de volver atras.
            </Typography>
          </GridItem>
        </DialogContent>

        <DialogActions
          className={classes.modalFooter}
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#F9F5EB",
          }}
        >
          <Button
            fullWidth
            onClick={(e) => {
              handleStart(e, estado);
            }}
            style={{ borderColor: "#F9F5EB", backgroundColor: "#fff" }}
          >
            <Typography style={{ color: "#483838" }}>Iniciar</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
