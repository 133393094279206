import React, { useState, useEffect } from "react";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

export default function GenerarAsignacion({ handle, codigo }) {
  const classes = useStyles();
  const [tecnico, setTecnico] = useState();
  const [users, setUsers] = useState();
  const [comentarios, setComentarios] = useState();

  const getData = async () => {
    const resultUsers = await axios.get(
      // `http://localhost:5005/api/userRdtt/users`
      // `http://localhost:5005/api/users/userstecnicos`
      `https://epstool.com/api/users/userstecnicos`
    );
    setUsers(resultUsers.data);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(tecnico);
  console.log(comentarios);

  const handleAsignacion = async () => {
    const decoded = jwt_decode(localStorage.jwtToken);

    const data = {
      userAsigna: decoded.id,
      userName: decoded.name,
      user: tecnico ? tecnico : "",
      comentarios: comentarios ? comentarios : "",
    };
    // axios.put(`http://localhost:5005/api/rdtt/asignacion/${codigo}`, data);
    await axios.put(`https://epstool.com/api/rdtt/asignacion/${codigo}`, data);
    setTecnico("");
    handle();

    window.location.reload();
  };

  console.log(tecnico);

  return (
    <GridContainer style={{ marginTop: 20, padding: 6 }}>
      <GridItem xs={12} sm={12}>
        <InputLabel>Tecnico:</InputLabel>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.label}>
            Selecione técnico a asignar
          </InputLabel>
          <Select
            value={tecnico}
            onChange={(e) => setTecnico(e.target.value)}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Selecione
            </MenuItem>
            {users &&
              users.map((user, x) => (
                <MenuItem
                  key={user._id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={user._id}
                >
                  {x + 1} - {user.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={12}>
        <InputLabel>Comentarios</InputLabel>
        <CustomInput
          labelText="Ingresa tu comentario"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              setComentarios(event.target.value);
            },
            type: "text",
          }}
        />
      </GridItem>

      <GridItem
        xs={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <Button onClick={handleAsignacion} color="success">
          Guardar
        </Button>
        <Button simple style={{ color: "#ef9a9a" }} onClick={handle}>
          cancelar
        </Button>
      </GridItem>
    </GridContainer>
  );
}
