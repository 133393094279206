import React, { useState } from "react";
import axios from "axios";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Escalar({ handleCloseScalar, openScalar, cod }) {
  const history = useHistory();
  const classes = useStyles();
  const [comentarios, setComentarios] = useState("");

  const handleScalar = async (e) => {
    e.preventDefault();
    const obj = {
      comentarios: comentarios,
    };
    // await axios.put(`http://localhost:5005/api/rdtt/escalar/${cod}`, obj);
    await axios.put(`https://epstool.com/api/rdtt/escalar/${cod}`, obj);
    history.push("/dashboard");
  };

  return (
    <Modal
      open={openScalar}
      onClose={handleCloseScalar}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CardHeader
          className={`${classes.cardHeader} ${classes.center}`}
          color="success"
        >
          <GridItem xs={12} md={12} lg={12}>
            <Typography variant="h4" align="center">
              Especifica tu traspaso
            </Typography>
          </GridItem>
        </CardHeader>
        <GridItem xs={12} sm={12}>
          <InputLabel style={{ marginTop: 20 }} className={classes.label}>
            Comentarios
          </InputLabel>
          <CustomInput
            labelText="Ingresa tu razón"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setComentarios(event.target.value);
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <Button onClick={(e) => handleScalar(e)} color="success">
            Escalar
          </Button>
          <Button
            simple
            style={{ color: "#ef9a9a" }}
            onClick={handleCloseScalar}
          >
            cancelar
          </Button>
        </GridItem>
      </Box>
    </Modal>
  );
}
