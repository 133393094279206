import React, { useState, useEffect } from "react";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import MessageIcon from "@material-ui/icons/Message";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PauseIcon from "@material-ui/icons/Pause";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import Pausa from "views/Forms/Pausa";
import ReporteFinalizado from "views/Forms/ReporteFinalizado";
import jwt_decode from "jwt-decode";
//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

import CreateNota from "views/Forms/CreateNota";
import GenerarAsignacion from "views/Forms/GenerarAsignacion";
import AsignarCompañero from "views/Forms/AsignarCompañero";
import Escalar from "views/Forms/Escalar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

export default function Detalle(props) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [changes, setChanges] = useState(0);
  const [allData, setAllData] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [users, setUsers] = useState();
  const [ayudante, setAyudante] = useState();
  const [alertStart, setAlertStart] = useState(false);

  const [openPausa, setOpenPausa] = useState(false);
  const handleOpenPausa = () => setOpenPausa(true);
  const handleClosePausa = () => setOpenPausa(false);

  const [openFinish, setOpenFinish] = useState(false);
  const handleOpenFinish = () => setOpenFinish(true);
  const handleCloseFinish = () => setOpenFinish(false);

  const [openCompañero, setOpenCompañero] = useState(false);
  const handleOpenCompañero = () => setOpenCompañero(true);
  const handleCloseCompañero = () => setOpenCompañero(false);

  const [openScalar, setOpenScalar] = useState(false);
  const handleOpenScalar = () => setOpenScalar(true);
  const handleCloseScalar = () => setOpenScalar(false);

  const cod = props.match.params.codigo;
  const decoded = jwt_decode(localStorage.jwtToken);

  const getData = async () => {
    const info = await axios.get(
      `http://localhost:5005/api/rdtt/detalle/${cod}`
      // `https://epstool.com/api/rdtt/detalle/${cod}`
    );
    if (decoded.app[0].codigoPrivilegio === 2) {
      console.log(localStorage.userConfig);
      const info = await axios.get(
        `http://localhost:5005/api/rdtt/trabajosTecnicos/${decoded.id}`
        // `https://epstool.com/api/rdtt/trabajosTecnicos/${decoded.id}`
      );
      // setData(info.data);
      // setAllData(info.data.filter((el) => el.estado !== 3));
      setAllData(info.data);
    } else if (decoded.app[0].codigoPrivilegio === 3) {
      const info = await axios.get(
        `http://localhost:5005/api/rdtt/trabajosTecnicos`
        // `https://epstool.com/api/rdtt/trabajosTecnicos`
      );
      // setData(info.data);
      setAllData(info.data.filter((el) => el.estado !== 3));
    }
    setData(info.data);
    const user = info.data.asignar.map((el) => el.user);
    setUsers(user[0]);
    const ayudante = info.data.asignar.map((el) => el.userCompañero);
    setAyudante(ayudante[0]);
  };
  // CARGA INICIAL
  useEffect(() => {
    getData();

    let ourDate = new Date();

    let pastDate = ourDate.getDate() - 7;

    console.log("filter", pastDate);
  }, [changes]);

  console.log("ayudante", ayudante);
  console.log("data", data);
  console.log("decoded", decoded);
  console.log("AllData", allData);
  console.log("ayudante", ayudante);

  const handleReanudar = async (e) => {
    e.preventDefault();
    let info = allData.filter((el) => el.estado === 2);
    console.log("infoPausa", info);
    for (let i = 0; i < info.length; i++) {
      // axios.put(`http://localhost:5005/api/rdtt/pausado/${info[i].codigo}`);
      await axios.put(`https://epstool.com/api/rdtt/pausado/${info[i].codigo}`);
      console.log("states", info[i].estado);
    }
    // axios.put(`http://localhost:5005/api/rdtt/reanudar-pausa/${cod}`);
    await axios.put(`https://epstool.com/api/rdtt/reanudar-pausa/${cod}`);
    setChanges(changes + 1);
  };

  const handleStart = async (e) => {
    e.preventDefault();
    let info = allData.filter((el) => el.estado === 2);
    for (let i = 0; i < info.length; i++) {
      // axios.put(`http://localhost:5005/api/rdtt/pausado/${info[i].codigo}`);
      await axios.put(`https://epstool.com/api/rdtt/pausado/${info[i].codigo}`);
    }

    // await axios.post(`http://localhost:5005/api/rdtt/iniciar/${cod}`);
    await axios.post(`https://epstool.com/api/rdtt/iniciar/${cod}`);
    setChanges(changes + 1);
    setAlertStart(false);
  };

  const handleDeleteAyudante = async (id) => {
    const data = ayudante.filter((el) => el._id !== id);
    const info = {
      userCompañero: data,
    };
    await axios.put(
      // `http://localhost:5005/api/rdtt/ayudante-delete/${cod}`,
      `https://epstool.com/api/rdtt/ayudante-delete/${cod}`,
      info
    );
    setChanges(changes + 1);
  };

  return (
    <GridContainer>
      <Card>
        <CardHeader
          className={`${classes.cardHeader} ${classes.center}`}
          color="success"
        >
          <GridItem xs={12} md={12} lg={12}>
            <Typography variant="h4" align="center">
              Detalle de reporte
            </Typography>
          </GridItem>
        </CardHeader>
        <CardBody>
          <GridContainer style={{ marginTop: 12 }}>
            <GridItem xs={12} md={7} sm={6} style={{ marginTop: 12 }}>
              <Typography>
                Codigo: {data && data.codigo ? data.codigo : ""}
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={6} md={5} style={{ marginTop: 12 }}>
              <Typography>
                {" "}
                Estado:{" "}
                {data && data.estado === 0 && data?.asignar.length === 0
                  ? "Nuevo sin asignar"
                  : "" || (data?.asignar.length > 0 && data?.estado === 0)
                  ? "Escalado"
                  : "" || (data && data.estado === 1)
                  ? "Nuevo asignado"
                  : "" || (data && data.estado === 2)
                  ? "En proceso"
                  : "" || (data && data.estado === 3)
                  ? "Terminado"
                  : "" || (data && data.estado === 4)
                  ? "Pausado"
                  : ""}
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: 12 }}>
            <GridItem xs={12} md={7} sm={6} style={{ marginTop: 12 }}>
              <Typography>
                Fecha de creacion:{" "}
                {data && data.fechaCreacion
                  ? new Date(data.fechaCreacion * 1000).toLocaleString()
                  : ""}
              </Typography>
            </GridItem>
            <GridItem xs={12} md={5} sm={6} style={{ marginTop: 12 }}>
              <Typography>Categoria: {data ? data.categoria : ""}</Typography>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: 12 }}>
            <GridItem xs={12} md={7} sm={6} style={{ marginTop: 12 }}>
              <Typography>Reporte: {data ? data.reporte : ""}</Typography>
            </GridItem>

            <GridItem xs={12} md={5} sm={6} style={{ marginTop: 12 }}>
              <Typography>
                Asignado a:{" "}
                {data?.asignar && users ? users.name : "Reporte sin asignar"}
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: 12 }}>
            <GridItem xs={12} md={7} sm={6} style={{ marginTop: 12 }}>
              <Typography>Prioridad: {data ? data.prioridad : ""}</Typography>
            </GridItem>
            <GridItem xs={12} md={5} sm={6} style={{ marginTop: 12 }}>
              <Typography>
                Ubicaion: {data ? data.ubicacion : "Reporte no asignado"}
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: 12 }}>
            <GridItem xs={12} md={7} sm={6} style={{ marginTop: 12 }}>
              <Typography>
                Solicitante: {data ? data.solicitante : ""}
              </Typography>
            </GridItem>
            <GridItem xs={12} md={5} sm={6} style={{ marginTop: 12 }}>
              <Box
                style={{
                  width: "100%",
                  maxHeight: 120,
                  // border: "1px solid blue",
                  display: "grid",
                  gridTemplateColumns:
                    ayudante?.length > 1 ? "170px 170px" : "100px 180px",
                  alignItems: "center",
                  overflow: "auto",
                }}
              >
                <Typography>Auxiliares: &nbsp;</Typography>
                {ayudante ? (
                  ayudante.map((el) => (
                    <Box key={el._id} display="flex" alignItems="center">
                      <Typography> {el.name}</Typography>
                      <Tooltip title="Eliminar">
                        <IconButton
                          onClick={() => handleDeleteAyudante(el._id)}
                          style={{ color: "red" }}
                        >
                          <DeleteForeverIcon style={{ fontSize: "18px" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ))
                ) : (
                  <Typography>Sin auxiliares</Typography>
                )}
              </Box>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: 12 }}>
            {data && data.files
              ? data.files.map((el) => {
                  return (
                    <GridItem
                      xs={12}
                      md={5}
                      sm={6}
                      style={{ display: "flex", marginTop: 12 }}
                      key={el.url}
                    >
                      <Typography style={{ marginRight: 10 }}>
                        Archivos
                      </Typography>
                      <Link
                        style={{ marginTop: 2 }}
                        underline="hover"
                        href={el.url}
                      >
                        {el.filename}
                      </Link>
                    </GridItem>
                  );
                })
              : ""}
          </GridContainer>

          <GridContainer
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 12,
            }}
          >
            <GridContainer justifyContent="center" style={{ marginLeft: 10 }}>
              {decoded.app[0].codigoPrivilegio === 2 ? (
                <GridItem xs={12} sm={2}>
                  <Button onClick={handleOpenScalar} color="success">
                    escalar
                  </Button>
                </GridItem>
              ) : (
                ""
              )}
              {data && data.estado !== 3 ? (
                <GridItem xs={12} sm={2}>
                  <Button onClick={handleOpen2} color="success">
                    Reasignar
                  </Button>
                </GridItem>
              ) : (
                <GridItem xs={12} sm={2}>
                  <Button disabled onClick={handleOpen2} color="success">
                    Reasignar
                  </Button>
                </GridItem>
              )}
              <GridItem xs={12} sm={2}>
                <Button onClick={handleOpen} color="success">
                  Crear nota
                </Button>
              </GridItem>
              {data && data.estado !== 3 ? (
                <GridItem xs={12} sm={3}>
                  <Button onClick={handleOpenCompañero} color="success">
                    Asignar auxiliares
                  </Button>
                </GridItem>
              ) : (
                <GridItem xs={12} sm={3}>
                  <Button
                    disabled
                    onClick={handleOpenCompañero}
                    color="success"
                  >
                    Asignar compañero
                  </Button>
                </GridItem>
              )}

              {data ? (
                <GridItem xs={12} sm={3}>
                  {data.estado === 1 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Acciones:</Typography>
                      <Tooltip title="Iniciar">
                        <IconButton
                          onClick={() => setAlertStart(true)}
                          style={{ color: "#4caf50" }}
                        >
                          <PlayCircleFilledWhiteIcon
                            style={{ fontSize: "40px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  {data.estado === 2 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>Acciones:</Typography>

                      <Tooltip title="Finalizar">
                        <IconButton
                          onClick={handleOpenFinish}
                          style={{ color: "#03a9f4" }}
                        >
                          <AssignmentTurnedInIcon
                            style={{ fontSize: "40px" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Pausa">
                        <IconButton onClick={handleOpenPausa}>
                          <PauseIcon style={{ fontSize: "40px" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {data.estado === 4 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>Acciones:</Typography>

                      <Tooltip title="Reanudar">
                        <IconButton
                          onClick={(e) => {
                            handleReanudar(e);
                          }}
                        >
                          <PlayArrowIcon style={{ fontSize: "40px" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </GridItem>
              ) : (
                ""
              )}
            </GridContainer>
          </GridContainer>
        </CardBody>
      </Card>
      {data && data.reporteTecnico
        ? data.reporteTecnico.map((el) => {
            return (
              <GridContainer>
                {el.comentarios ? (
                  <Card>
                    <CardHeader>
                      <CardIcon color="success">
                        <MessageIcon style={{ color: "#fff" }} />
                      </CardIcon>
                      <Typography variant="inherit" component="h4">
                        Reporte Tecnico
                      </Typography>
                    </CardHeader>
                    <CardBody>
                      <GridItem xs={12} sm={12} md={12}>
                        <Typography variant="inherit">
                          {" "}
                          Reporte hecho por: {el.userName}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Typography variant="inherit">
                          Materiales: {el.materiales}
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Typography variant="inherit">
                          Comentarios: {el.comentarios}
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Typography variant="inherit">
                          Duracion:{" "}
                          {data && data.duracion
                            ? `${Math.floor(data.duracion / 60)} min`
                            : ""}
                        </Typography>
                      </GridItem>
                      {el.files
                        ? el.files.map((el) => {
                            return (
                              <GridItem
                                key={el.handle}
                                xs={12}
                                md={12}
                                sm={12}
                                style={{ display: "flex" }}
                              >
                                <Typography variant="inherit">
                                  Archivos
                                </Typography>
                                <Link
                                  style={{ marginLeft: 15 }}
                                  underline="hover"
                                  href={el.url}
                                >
                                  {el.filename}
                                </Link>
                              </GridItem>
                            );
                          })
                        : ""}
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}
              </GridContainer>
            );
          })
        : ""}
      {data
        ? data.tiempos.map((el) => {
            return (
              <GridContainer>
                <Card>
                  <CardHeader>
                    <CardIcon color="success">
                      <MessageIcon style={{ color: "#fff" }} />
                    </CardIcon>
                    <Typography variant="inherit" component="h4">
                      {el.fechaRetorno
                        ? `Reporte Pasuado el ${new Date(
                            el.fechaPausa * 1000
                          ).toLocaleString()} hasta el ${new Date(
                            el.fechaRetorno * 1000
                          ).toLocaleString()}`
                        : `Reporte Pausado el ${new Date(
                            el.fechaPausa * 1000
                          ).toLocaleString()} `}
                    </Typography>
                  </CardHeader>
                  <CardBody>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography style={{ fontSize: 15 }} variant="inherit">
                        {" "}
                        Razon de la pausa: {el.razon}
                      </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography style={{ fontSize: 15 }} variant="inherit">
                        {" "}
                        {`  Pausado por: ${decoded ? decoded.name : ""}`}
                      </Typography>
                    </GridItem>
                  </CardBody>
                </Card>
              </GridContainer>
            );
          })
        : ""}

      {data && data.nota
        ? data.nota.map((el) => {
            return (
              <GridContainer>
                <Card>
                  <CardHeader>
                    <CardIcon color="success">
                      <MessageIcon style={{ color: "#fff" }} />
                    </CardIcon>
                    <Typography variant="inherit" component="h4">
                      Notas
                    </Typography>
                  </CardHeader>
                  <CardBody>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography style={{ fontSize: 15 }} variant="inherit">
                        {`Nota: ${el.comentario}`}
                      </Typography>
                    </GridItem>
                  </CardBody>
                </Card>
              </GridContainer>
            );
          })
        : ""}

      {data?.comentarios ? (
        <GridContainer>
          <Card>
            <CardHeader>
              <CardIcon color="success">
                <MessageIcon style={{ color: "#fff" }} />
              </CardIcon>
              <Typography variant="inherit" component="h4">
                Detalle de traspaso a administracion
              </Typography>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <Typography style={{ fontSize: 15 }} variant="inherit">
                  {`Comentario: ${data?.comentarios}`}
                </Typography>
              </GridItem>
            </CardBody>
          </Card>
        </GridContainer>
      ) : (
        ""
      )}

      <GridContainer>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="success"
            >
              <GridItem xs={12} md={12} lg={12}>
                <Typography variant="h4" align="center">
                  Crea tu nota
                </Typography>
              </GridItem>
            </CardHeader>
            <CreateNota handle={handleClose} cod={cod} />
          </Box>
        </Modal>
      </GridContainer>
      <GridContainer>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="success"
            >
              <GridItem xs={12} md={12} lg={12}>
                <Typography variant="h4" align="center">
                  Asignación de reporte
                </Typography>
              </GridItem>
            </CardHeader>
            <GenerarAsignacion handle={handleClose2} codigo={cod} />
          </Box>
        </Modal>
      </GridContainer>
      <GridContainer>
        <Modal
          open={openCompañero}
          onClose={handleCloseCompañero}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="success"
            >
              <GridItem xs={12} md={12} lg={12}>
                <Typography variant="h4" align="center">
                  Asignación de auxiliares
                </Typography>
              </GridItem>
            </CardHeader>
            <AsignarCompañero
              changes={changes}
              setChanges={setChanges}
              handle={handleCloseCompañero}
              codigo={cod}
            />
          </Box>
        </Modal>
      </GridContainer>
      <GridContainer>
        <Pausa
          handleClosePausa={handleClosePausa}
          cod={cod}
          openPausa={openPausa}
        />
      </GridContainer>
      <GridContainer>
        <Escalar
          handleCloseScalar={handleCloseScalar}
          cod={cod}
          openScalar={openScalar}
        />
      </GridContainer>
      <GridContainer>
        <ReporteFinalizado
          cod={cod}
          handleCloseFinish={handleCloseFinish}
          openFinish={openFinish}
        />
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={alertStart}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setAlertStart(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{ backgroundColor: "#F9F5EB" }}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setAlertStart(false);
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ backgroundColor: "#F9F5EB" }}
        >
          <GridItem
            style={{
              width: "350px",
              height: "150px",
            }}
          >
            <Typography variant="h5">
              ¿Estas seguro de querer iniciar este trabajo?
            </Typography>
            <br />
            <Typography variant="inherit">
              - Esta accion no se puede deshacer, si inicas el trabajo no habra
              manera de volver atras.
            </Typography>
          </GridItem>
        </DialogContent>

        <DialogActions
          className={classes.modalFooter}
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#F9F5EB",
          }}
        >
          <Button
            fullWidth
            onClick={(e) => {
              handleStart(e);
            }}
            style={{ borderColor: "#F9F5EB", backgroundColor: "#fff" }}
          >
            <Typography style={{ color: "#483838" }}>Iniciar</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
