import Dashboard from "views/Dashboard/Dashboard.js";
import CreateReporte from "views/Forms/CreateReporte";
import Planilla from "views/Planilla/Planilla";
import Estadisticas from "views/Estadisticas/Estadisticas";
// import Estadisticas from "views/Estadisticas/Estadisticas";
import Detalle from "views/Detalle/Detalle";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AppsIcon from "@material-ui/icons/Apps";
import Applications from "views/Applications/Applications";

import Timeline from "@material-ui/icons/Timeline";
import GeneralGrafics from "views/Estadisticas/components/GeneralGrafics";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/detalle/:codigo",
    name: "Detalle de reporte",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Detalle,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 2,
    path: "/crearReporte",
    name: "Crear Reporte",
    rtlName: "لوحة القيادة",
    icon: AssignmentLateIcon,
    component: CreateReporte,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 3,
    path: "/planilla",
    name: "Planilla",
    rtlName: "لوحة القيادة",
    icon: ListAltIcon,
    component: Planilla,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 5,
    path: "/estadisticas",
    name: "Estadisticas",
    rtlName: "لوحة القيادة",
    icon: Timeline,
    component: GeneralGrafics,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/generarEstadisticas",
    name: "Estadisticas",
    rtlName: "لوحة القيادة",
    icon: Timeline,
    component: Estadisticas,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 4,
    path: "/application",
    name: "Aplicaciones",
    rtlName: "لوحة القيادة",
    icon: AppsIcon,
    component: Applications,
    layout: "/admin",
  },
];
export default dashRoutes;
