import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import rondas from "assets/img/rondas.jpg";

import jwt_decode from "jwt-decode";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Applications() {
  const initialState = {
    eps: false,
    reportes: false,
    rondas: false,
    polos: false,
    vaisala: false,
  };
  const [user, setUser] = React.useState(initialState);

  const classes = useStyles();
  const fetchData = async () => {
    try {
      let userPre = jwt_decode(localStorage.jwtToken);
      setUser(userPre);
    } catch (error) {}
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={5} md={5}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Technical Rounds"
                height="160"
                image={rondas}
                title="Technical Rounds"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Rondas técnicas
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Sistema para la gestión de rondas técnicas en equipos del
                  departamento de facilidades.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              {user.rondas === false || user.rondas === undefined ? (
                <Button disabled block color="info">
                  No autorizado
                </Button>
              ) : (
                <Button
                  block
                  color="info"
                  onClick={() => {
                    // window.location.href = `http://localhost:3010/admin/dashboard?token=${localStorage.jwtToken}`;
                    window.location.href = `https://rondas.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                  }}
                >
                  Ir a Gestión Rondas
                </Button>
              )}
            </CardActions>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
