import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { PickerDropPane } from "filestack-react";

// core components

import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormLabel from "@material-ui/core/FormLabel";
// import AddAlert from "@material-ui/icons/AddAlert";
//Form
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
// import Snackbar from "components/Snackbar/Snackbar.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { solicitantes } from "./dataSolicitantes";
import GridItem from "components/Grid/GridItem";
import SweetAlert from "react-bootstrap-sweetalert";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import Typography from "@material-ui/core/Typography";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

const CreateReporte = () => {
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const classes = useStyles();
  const [reporte, setReporte] = useState("");
  const [tecnico, setTecnico] = useState("");
  const [auxiliares, setAuxiliares] = useState([]);
  const [categoria, setCategoria] = useState("");
  const [ubicacion, setUbicacion] = useState("");
  const [prioridad, setPrioridad] = useState("");
  const [emailSolicitante, setEmailSolicitante] = useState("");
  const [responsable, setResponsable] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [alerta, setAlerta] = useState(null);
  const [users, setUsers] = useState();

  let decoded = jwt_decode(localStorage.jwtToken);

  const getData = async () => {
    const resultUsers = await axios.get(
      `https://epstool.com/api/users/userstecnicos`
      // `http://localhost:5005/api/users/userstecnicos`
      // `http://localhost:5005/api/userRdtt/users`
    );
    // console.log(resultUsers.data)
    setUsers(resultUsers.data);

    decoded.app[0].codigoPrivilegio === 2 && setTecnico(decoded.id);
  };

  useEffect(() => {
    getData();
  }, []);

  const hideAlert = () => {
    setAlerta(null);
  };
  const successAlert = () => {
    setAlerta(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "100px" }}
        title="Reporte Tecnico"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        timeout={1500}
      >
        Tu reporte a sido enviado
      </SweetAlert>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errorMessage = {};
    if (!reporte) errorMessage.reporte = "Este campo es obligatorio";
    if (!categoria) errorMessage.categoria = "Debe selecionar una categoría";
    if (!prioridad) errorMessage.prioridad = "Debe selecionar una prioridad";
    if (!solicitante) errorMessage.solicitante = "Este campo es obligatorio";

    setErrors(errorMessage);

    if (Object.keys(errorMessage).length === 0) {
      let dataAsignacion = {
        userAsigna: decoded.id,
        userName: decoded.name,
        user: tecnico ? tecnico : null,
        userCompañero: auxiliares ? auxiliares : null,
      };

      const reporteToSave = {
        reporte: reporte,
        // tecnico: tecnico,
        ubicacion: ubicacion,
        categoria: categoria,
        prioridad: prioridad,
        solicitante: solicitante !== "otro" ? solicitante : responsable,
        emailSolicitante: emailSolicitante,
        files: files,
        asignar: dataAsignacion,
      };

      console.log("reporteToSave", reporteToSave);
      await axios
        // .post(
        //   reporteToSave
        // .post("http://localhost:5005/api/rdtt/nuevoRdtt", reporteToSave)
        .post("https://epstool.com/api/rdtt/nuevoRdtt", reporteToSave)
        .then((res) => {
          setErrors({});
          setReporte("");
          setUbicacion("");
          setCategoria("");
          setPrioridad("");
          setSolicitante("");
          tecnico ? setTecnico("") : "";
          setFiles([]);
          successAlert();
        })

        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };
  const handleListFiles = async (uploadedFiles) => {
    uploadedFiles.forEach((element) => {
      // console.log(element);

      setFiles((prevFiles) => [...prevFiles, element]);
    });
  };

  const handleDeleteFile = async (idFileToDelete) => {
    setFiles(files.filter((item) => item.uploadId !== idFileToDelete));
  };

  const getDataSolicitantes = (name) => {
    setSolicitante(name);
    const sltt = solicitantes.filter((el) => el.name === name);

    name === "otro"
      ? setEmailSolicitante(null)
      : setEmailSolicitante(sltt[0].email);
  };

  console.log(
    "solicitante y email",
    solicitante,
    emailSolicitante,
    responsable
  );

  console.log("auxiliares", auxiliares);

  return (
    <GridContainer>
      {alerta}
      <GridItem xs={12}>
        <Card>
          <CardHeader
            className={`${classes.cardHeader} ${classes.center}`}
            color="success"
          >
            <GridItem xs={12} md={12} lg={12}>
              <Typography variant="h4" align="center">
                Generar reporte
              </Typography>
            </GridItem>
          </CardHeader>
          <CardBody>
            {alerta}
            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Reporte:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <CustomInput
                  multiline
                  labelText="Reporte"
                  name="reporte"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: (e) => setReporte(e.target.value),
                    type: "text",
                    value: reporte,
                  }}
                />
                <Danger>
                  {errors.reporte && (
                    <div className="invalid-feedback">{errors.reporte}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            {decoded.app[0].codigoPrivilegio === 3 && (
              <GridContainer>
                <GridItem xs={12} sm={4} md={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Técnico:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={classes.selectLabel}
                    >
                      Selecione técnico a asignar
                    </InputLabel>
                    <Select
                      value={tecnico}
                      // onChange={(e) => handleMaquinas(e.target.value)}
                      onChange={(e) => setTecnico(e.target.value)}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione
                      </MenuItem>
                      {users &&
                        users.map((user, x) => (
                          <MenuItem
                            key={user._id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={user._id}
                          >
                            {x + 1} - {user.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <Danger>
                      {errors.tecnico && (
                        <div className="invalid-feedback">{errors.tecnico}</div>
                      )}
                    </Danger>
                  </FormControl>
                </GridItem>
              </GridContainer>
            )}

            {decoded.app[0].codigoPrivilegio === 2 && (
              <GridContainer>
                <GridItem xs={12} sm={4} md={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Tecnico:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={10}>
                  <CustomInput
                    disabled
                    multiline
                    name="tecnico"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      type: "text",
                      value: decoded.name,
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}

            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Categoría:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Selecione categoría del reporte
                  </InputLabel>
                  <Select
                    value={categoria}
                    // onChange={(e) => handleMaquinas(e.target.value)}
                    onChange={(e) => setCategoria(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"electrica"}
                    >
                      Eléctrico
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"ehs"}
                    >
                      EHS
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"area mecanica"}
                    >
                      Área mecanico
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"produccion"}
                    >
                      Producción
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"emergencia"}
                    >
                      Emergencia
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"cortinas"}
                    >
                      Cortinas
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"otro"}
                    >
                      Otro
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"proyectos"}
                    >
                      Proyectos
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"mantenimiento"}
                    >
                      Mantenimiento
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.categoria && (
                      <div className="invalid-feedback">{errors.categoria}</div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Auxiliares:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Selecione auxiliares
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={auxiliares}
                    onChange={(e) => setAuxiliares(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    {users &&
                      users.map((user, x) => (
                        <MenuItem
                          key={user._id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={user._id}
                        >
                          {x + 1} - {user.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            {categoria === "produccion" && (
              <GridContainer>
                <GridItem
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="inherit">
                    Producción: presión diferencial, temperatura y humedad
                    relativa, aire comprimido
                  </Typography>
                </GridItem>
              </GridContainer>
            )}
            {categoria === "cortinas" && (
              <GridContainer>
                <GridItem
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="inherit">
                    Cortinas: alarmas de baisalas
                  </Typography>
                </GridItem>
              </GridContainer>
            )}
            {categoria === "proyectos" && (
              <GridContainer>
                <GridItem
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="inherit">
                    Con problemas: Tarjeta amarilla y tarjeta roja(Especificar
                    el problema en el reporte)
                  </Typography>
                </GridItem>
              </GridContainer>
            )}

            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Solicitante:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Selecione un solicitante
                  </InputLabel>
                  <Select
                    value={solicitante}
                    // onChange={(e) => handleMaquinas(e.target.value)}
                    onChange={(e) => getDataSolicitantes(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    {solicitantes &&
                      solicitantes.map((user, x) => (
                        <MenuItem
                          key={user.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={user.name}
                        >
                          {x + 1} - {user.name}
                        </MenuItem>
                      ))}
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="otro"
                    >
                      Otro
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.solicitante && (
                      <div className="invalid-feedback">
                        {errors.solicitante}
                      </div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>
            {solicitante === "otro" && (
              <GridContainer>
                <GridItem xs={12} sm={4} md={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Responsable:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={10}>
                  <CustomInput
                    multiline
                    labelText="Responsable"
                    id="Responsable"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      onChange: (event) => {
                        setResponsable(event.target.value);
                      },
                      type: "text",
                      value: responsable,
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}

            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  prioridad:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Selecione la prioridad del reporte
                  </InputLabel>
                  <Select
                    value={prioridad}
                    onChange={(e) => setPrioridad(e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"normal"}
                    >
                      Normal
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={"emergencia"}
                    >
                      Emergencia
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.prioridad && (
                      <div className="invalid-feedback">{errors.prioridad}</div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>

            <GridContainer style={{ marginBottom: 20 }}>
              <GridItem xs={12} sm={4} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Ubicación:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <CustomInput
                  multiline
                  labelText="Zona"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: (e) => {
                      setUbicacion(e.target.value);
                    },
                    type: "text",
                    value: ubicacion,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Archivos:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <PickerDropPane
                  apikey={API_KEY}
                  onUploadDone={(res) => handleListFiles(res.filesUploaded)}
                  pickerOptions={{ maxFiles: 5 }}
                  onError={(error) => console.log(error)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <Typography
                  variant="inherit"
                  className={classes.labelHorizontal}
                >
                  Archivos Cargados:
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={8} md={10}>
                <List style={{ marginTop: "-7px" }}>
                  {files.length === 0 ? (
                    <p className={classes.labelHorizontalIzq}>
                      No hay archivos cargados
                    </p>
                  ) : (
                    files.map((file) => (
                      <ListItem
                        style={{ marginTop: "3.5%" }}
                        dense
                        key={file.uploadId}
                      >
                        <ListItemText primary={file.filename} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="open"
                            onClick={() => window.open(file.url, "_blank")}
                          >
                            <ImageSearchIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteFile(file.uploadId)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  )}
                </List>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} md={12}>
                <Button fullWidth color="success" onClick={handleSubmit}>
                  Crear reporte
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateReporte;
