import React, { useState } from "react";
import axios from "axios";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader.js";
import { PickerDropPane } from "filestack-react";
import Danger from "components/Typography/Danger";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ReporteFinalizado({
  handleCloseFinish,
  openFinish,
  cod,
}) {
  const classes = useStyles();
  const [materiales, setMateriales] = useState("");
  const [comentarios, setComentarios] = useState("");
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const [files, setFiles] = useState([]);
  const [error, setError] = useState();

  const handleListFiles = async (uploadedFiles) => {
    uploadedFiles.forEach((element) => {
      console.log(element);

      setFiles((prevFiles) => [...prevFiles, element]);
    });
  };

  const handleDeleteFile = async (idFileToDelete) => {
    setFiles(files.filter((item) => item.uploadId !== idFileToDelete));
  };

  const handleFinish = async (e, cod) => {
    const decoded = jwt_decode(localStorage.jwtToken);
    e.preventDefault();

    let errorMessage = {};

    if (!comentarios && files.length === 0) {
      errorMessage.comentarios = "Este campo es obligatorio";
    }

    setError(errorMessage);
    if (Object.keys(errorMessage).length === 0) {
      const info = {
        userAsigna: decoded.id,
        userName: decoded.name,
        materiales: materiales,
        comentarios: comentarios,
        files: files,
      };

      await axios.post(`https://epstool.com/api/rdtt/finalizar/${cod}`, info);
      // axios.post(`http://localhost:5005/api/rdtt/finalizar/${cod}`, info);
      window.location.reload();
      console.log(info);

      console.log("cod", cod);
    } else {
      console.log({ message: "no lleno los campos obligatorios" });
    }
  };

  console.log(error);

  return (
    <Modal
      open={openFinish}
      onClose={handleCloseFinish}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CardHeader
          className={`${classes.cardHeader} ${classes.center}`}
          color="success"
        >
          <GridItem xs={12} md={12} lg={12}>
            <Typography variant="h4" align="center">
              Finaliza tu reporte
            </Typography>
          </GridItem>
        </CardHeader>
        <GridItem xs={12} sm={12}>
          <InputLabel style={{ marginTop: 20 }} className={classes.label}>
            Materiales
          </InputLabel>
          <CustomInput
            labelText="Ingresa aqui tus materiales"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setMateriales(event.target.value);
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <InputLabel style={{ marginTop: 20 }} className={classes.label}>
            Comentarios
          </InputLabel>
          <CustomInput
            labelText="Ingresa tu comentario"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setComentarios(event.target.value);
              },
              type: "text",
            }}
          />
          {error && error.comentarios && <Danger>{error.comentarios}</Danger>}
        </GridItem>
        <GridItem xs={12} sm={12}>
          <PickerDropPane
            apikey={API_KEY}
            onUploadDone={(res) => handleListFiles(res.filesUploaded)}
            pickerOptions={{ maxFiles: 5 }}
            onError={(error) => console.log(error)}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <List>
            {files.length === 0 ? (
              <p>No hay archivos cargados</p>
            ) : (
              files.map((file) => (
                <ListItem
                  style={{ marginTop: "3.5%" }}
                  dense
                  key={file.uploadId}
                >
                  <ListItemText primary={file.filename} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="open"
                      onClick={() => window.open(file.url, "_blank")}
                    >
                      <ImageSearchIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteFile(file.uploadId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            )}
          </List>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <Button onClick={(e) => handleFinish(e, cod)} color="success">
            Reportar
          </Button>
          <Button
            simple
            style={{ color: "#ef9a9a" }}
            onClick={handleCloseFinish}
          >
            cancelar
          </Button>
        </GridItem>
      </Box>
    </Modal>
  );
}
