import React, { useEffect, useState } from "react";

//MUI
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

//Components MUI internos
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";

// @material-ui/core components styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

//React DateTime
import Datetime from "react-datetime";
//MOCK
import { categorias } from "mock/_categorias";
import { estados } from "mock/_categorias";
//Api call
import axios from "axios";
import TotalDonutPrioridad from "./TotalDonutPrioridad";
import TotalDonutCategoria from "./TotalDonutCategoria";
import TotalDonutSolicitante from "./TotalDonutSolicitante";
import { Box, Grid } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function GeneralGrafics(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8} sm={9} md={9}>
        <Typography variant="h4">Gráficos Generales</Typography>
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        <Button
          onClick={() => props.history.push("/admin/generarEstadisticas")}
          color="success"
        >
          Generar grafico
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} styles={{ height: 700 }}>
        <TotalDonutCategoria />
      </Grid>
      <Grid item xs={12} sm={6} md={6} styles={{ height: 700 }}>
        <TotalDonutSolicitante />
      </Grid>
      <Grid item xs={12} sm={6} md={6} styles={{ height: 700 }}>
        <TotalDonutPrioridad />
      </Grid>
    </Grid>
  );
}
