export const categorias = [
  {
    name: "Eléctrico",
    value: "electrica",
  },
  {
    name: "EHS",
    value: "ehs",
  },
  {
    name: "Área mecanico",
    value: "area mecanica",
  },
  {
    name: "Producción",
    value: "produccion",
  },
  {
    name: "Emergencia",
    value: "emergencia",
  },
  {
    name: "Cortinas",
    value: "cortinas",
  },
  {
    name: "Otro",
    value: "otro",
  },
  {
    name: "Proyectos",
    value: "proyectos",
  },
  {
    name: "Mantenimiento",
    value: "mantenimiento",
  },
];
export const estados = [
  {
    name: "Nuevos Sin Asignar",
    value: 0,
  },
  {
    name: "Nuevos Asignados",
    value: 1,
  },
  {
    name: "En Proceso",
    value: 2,
  },
  {
    name: "Terminado",
    value: 3,
  },
  {
    name: "Pausados",
    value: 4,
  },
];
