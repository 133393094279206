import React, { useState, useEffect } from "react";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function AsignarCompañero({
  handle,
  codigo,
  setChanges,
  changes,
}) {
  const classes = useStyles();
  const [auxiliares, setAuxiliares] = useState([]);
  const [users, setUsers] = useState([]);

  const getData = async () => {
    const resultUsers = await axios.get(
      `https://epstool.com/api/users/userstecnicos`
      // `http://localhost:5005/api/users/userstecnicos`
      // `http://localhost:5005/api/userRdtt/users`
    );
    setUsers(resultUsers.data);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(auxiliares);

  const handleSubmit = async () => {
    let info = {
      userCompañero: auxiliares,
    };
    await axios.put(
      `https://epstool.com/api/rdtt/asignar-ayudante/${codigo}`,
      // `http://localhost:5005/api/rdtt/asignar-ayudante/${codigo}`,
      info
    );
    setChanges(changes + 1);
    setAuxiliares("");
    handle();
  };

  return (
    <GridContainer style={{ marginTop: 20, padding: 6 }}>
      <GridItem xs={12} sm={12}>
        <InputLabel>Auxiliares:</InputLabel>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.label}>Selecione compañero</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            defaultValue={[]}
            value={auxiliares}
            onChange={(e) => setAuxiliares(e.target.value)}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Selecione
            </MenuItem>
            {users &&
              users.map((user, x) => (
                <MenuItem
                  key={user._id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={user._id}
                >
                  {x + 1} - {user.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem
        xs={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <Button onClick={() => handleSubmit()} color="success">
          Guardar
        </Button>
        <Button simple style={{ color: "#ef9a9a" }} onClick={handle}>
          cancelar
        </Button>
      </GridItem>
    </GridContainer>
  );
}
