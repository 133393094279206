import React, { useState } from "react";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function CreateNota({ handle, cod }) {
  const classes = useStyles();
  const [nota, setNota] = useState("");

  const handleSubmit = async (e, c) => {
    e.preventDefault();

    const obj = {
      comentario: nota ? nota : "",
    };
    await axios.put(`https://epstool.com/api/rdtt/nota/${c}`, obj);
    // axios.put(`http://localhost:5005/api/rdtt/nota/${c}`, obj);
    window.location.reload();
  };

  return (
    <GridContainer style={{ marginTop: 20, padding: 5 }}>
      <GridItem xs={12} sm={12}>
        <InputLabel className={classes.label}>Nota</InputLabel>
        <CustomInput
          labelText="Ingresa tu nota"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              setNota(event.target.value);
            },
            type: "text",
          }}
        />
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <Button onClick={(e) => handleSubmit(e, cod)} color="success">
          Guardar
        </Button>
        <Button simple style={{ color: "#ef9a9a" }} onClick={handle}>
          cancelar
        </Button>
      </GridItem>
    </GridContainer>
  );
}
